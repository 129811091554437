<template>
  <div class="content">
    <!-- <PageHeader
      title="Omni Business"
      :subtitle="$t('sms.usage-report.header')"
    /> -->
    <PageHeader
      title="Chat"
      :subtitle="$t('sms.usage-report.header')"
    />
    <div class="page-content container-fluid">
      <div class="card card-tabs">
        <div class="card-header">
          <div class="full">
            <div class="row">
              <div class="col-sm-8">
                <ul class="nav nav-tabs">
                  <li class="nav-item" role="presentation">
                    <router-link
                      to="/live/reports/stat"
                      class="nav-link"
                      replace
                    >
                      <span class="material-symbols-outlined"
                        >insert_chart</span
                      >
                      {{ $tc('app.report', 1) }}
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      to="/live/reports/history"
                      class="nav-link"
                      replace
                    >
                      <span class="material-symbols-outlined">history</span>
                      {{ $t('app.history') }}
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{ name: 'OmniBusinessReportExport' }"
                      class="nav-link"
                      replace
                      ><span class="material-symbols-outlined">
                        cloud_download </span
                      >{{ $tc('app.file', 2) }}</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="col-sm-4">
                <ul
                  class="nav-actions d-flex flex-row-reverse align-items-center"
                >
                  <li>
                    <b-dropdown variant="light" :right="true" no-caret>
                      <template #button-content>
                        <span class="material-icons-outlined title"
                          >file_download</span
                        >
                      </template>
                      <b-dropdown-item @click="exportData('csv')"
                        >CSV</b-dropdown-item
                      >
                      <b-dropdown-item @click="exportData('xlsx')"
                        >XLSX</b-dropdown-item
                      >
                      <b-dropdown-item @click="exportData('pdf')"
                        >PDF</b-dropdown-item
                      >
                    </b-dropdown>
                  </li>
                  <li>
                    <button class="btn btn-light" @click="reload()">
                      <span class="material-icons-outlined title">refresh</span>
                    </button>
                  </li>
                  <li>
                    <button class="btn btn-light calendar">
                      <span class="material-icons-outlined title"
                        >calendar_today</span
                      >
                      <date-range-picker
                        class="form-control"
                        :format="'YYYY-MM-DD'"
                        :options="options"
                        @change="changeDate"
                      />
                    </button>
                  </li>
                  <li>
                    <button class="btn btn-light" @click="showFilter()">
                      <span class="material-icons-outlined title"
                        >filter_list</span
                      >
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body block-el">
          <router-view
            ref="view"
            :filters="form.filters"
            :start-date="form.date.gte"
            :end-date="form.date.lte"
          />
        </div>
      </div>
    </div>
    <modal ref="filter-modal" title="Filtros" size="xl">
      <div class="row m-t-30">
        <div class="col-3">
          <h2 class="text-center">Número</h2>
          <div class="form-group">
            <tel-input ref="mobile_number" v-model="filter.phone" />
          </div>
        </div>
        <div class="col-3">
          <h2 class="text-center">Agente</h2>
          <div class="form-group">
            <select-input
              :options="agents"
              v-model="filter.agentId"
              label="name"
              :reduce="(field) => field.id"
            ></select-input>
          </div>
        </div>
        <div class="col-3">
          <h2 class="text-center">Tabulação</h2>
          <div class="form-group">
            <select-input
              :options="tabs"
              v-model="filter.tabs"
              label="name"
              :reduce="(field) => field.id"
              multiple
            ></select-input>
          </div>
        </div>
        <div class="col-3">
          <h2 class="text-center">Canais</h2>
          <div class="form-group">
            <select-input
              :options="channels"
              v-model="filter.channels"
              label="description"
              :reduce="(field) => field.value"
              multiple
            ></select-input>
          </div>
        </div>
        <div class="col-3">
          <h2 class="text-center">Tags</h2>
          <div class="form-group">
            <select-input
              :options="tags"
              v-model="filter.tags"
              label="name"
              :reduce="(field) => field.id"
              multiple
            ></select-input>
          </div>
        </div>
        <div class="col-3">
          <h2 class="text-center">Protocolo</h2>
          <div class="form-group">
            <input type="text" class="form-control" v-model="filter.protocol" />
          </div>
        </div>
      </div>

      <template #footer>
        <div class="d-flex justify-content-between w-100">
          <button
            @click="cleanFilter()"
            type="submit"
            class="btn btn-primary btn-outline"
          >
            {{ $t('app.clean') }}
          </button>
          <button @click="applyFilter()" type="submit" class="btn btn-primary">
            {{ $t('app.apply') }}
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';
import Modal from '@/components/Modal';
import TelInput from '@/components/form/TelInput.vue';

export default {
  name: 'OmniReports',
  components: {
    // Card,
    PageHeader,
    SelectInput,
    Modal,
    TelInput,
  },
  computed: {
    tabs() {
      return this.$live?.tabs || [];
    },
  },
  data() {
    const today = moment();

    return {
      agents: [],
      tags: [],
      channels: [
        { value: 'whatsapp', description: 'whatsapp' },
        { value: 'whatsapp-java', description: 'whatsapp (meta)' },
        { value: 'widget-java', description: 'WebChat' },
        { value: 'messenger', description: 'Facebook Messenger' },
        { value: 'rcs', description: 'RCS' },
        { value: 'telegram', description: 'Telegram' },
        { value: 'instagram', description: 'Instagram Direct' },
        { value: 'email', description: 'E-mail' },
        { value: 'google-business', description: 'Google Business' },
      ],
      filter: {
        phone: '',
        channels: [],
        tabs: [],
        tags: [],
        agentId: '',
        protocol: '',
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          agentId: '',
          channels: [],
          tabs: [],
          tags: [],
          protocol: '',
        },
      },
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
    };
  },
  created() {
    this.fetchAgents();
    this.fetchTags();
  },
  // Pai
  methods: {
    fetchAgents() {
      this.$store.dispatch('fetchOmniAgents').then((response) => {
        this.agents = response;
      });
    },
    fetchTags() {
      this.$store.dispatch('fetchOmniTags').then((response) => {
        this.tags = response;
      });
    },
    showFilter() {
      this.$refs['filter-modal'].toggle();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => this.reload());
    },
    cleanFilter() {
      this.filter = {
        agentId: '',
        channels: [],
        tabs: [],
        tags: [],
        protocol: '',
      };
      this.reload();
    },
    reload() {
      this.$refs.view.fetch();
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}

.items {
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;

  &.last {
    margin: 0;
  }
}

::v-deep.nav-actions {
  list-style-type: none;
  margin: 0;

  & > li {
    padding: 0.5rem;

    &:first-child {
      padding-right: 0;
    }

    & {
      .btn {
        position: relative;
        font-size: 1.5rem;
        padding: 0.5rem;

        &.calendar {
          input {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
        }
      }

      .dropdown-menu {
        left: 0px !important;
      }
    }
  }
}

/* Rebranding */
.card.card-tabs .card-header {
  & > .full {
    width: 100%;
    padding: 0 1rem;
    gap: 2.5rem;
    margin-bottom: 5px;
    border-bottom: 2px solid rgba(210, 221, 234, 0.5);
  }

  .nav.nav-tabs {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
  gap: 1.32rem;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}
</style>
